html,
body,
#root {
  height: 100%;
}

#components-layout-demo-top-side .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

body {
  margin: 0;
  padding: 0;
}

.ct-padding {
  padding-right: 8px;
  padding-left: 8px;
  /* Default padding for small screens */
}

.input1 {
  width: 100%;

  /* 小屏幕 */
  @media (max-width: 768px) {
    width: 132px;
  }

  /* 中等屏幕 */
  @media (min-width: 769px) and (max-width: 1200px) {
    width: 174px;
  }

  /* 大屏幕 */
  @media (min-width: 1201px) {
    width: 216px;
  }
}

.input2 {
  width: 100%;

  /* 小屏幕 */
  @media (max-width: 768px) {
    width: 92px;
  }

  /* 中等屏幕 */
  @media (min-width: 769px) and (max-width: 1200px) {
    width: 108px;
  }

  /* 大屏幕 */
  @media (min-width: 1201px) {
    width: 144px;
  }
}

@media (min-width: 1200px) {
  .ct-padding {
    padding-right: 40px;
    padding-left: 40px;
    /* Padding for extra-large screens */
  }
}